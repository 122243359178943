import React from "react"
import ActivityImg from "./activity-img.js"
import "./activity-list.css"

const ActivityList = ({ control, activities, images }) => {
  const cloudPath = "gatsby-source-cloudinary/activities/"

  const getImage = name => {
    const final = images.filter(
      img => img.node.public_id === `${cloudPath}${name}`
    )
    return final[0].node.secure_url
  }

  return (
    <section className="activity-list">
      {activities.map((node, id) => (
        <div
          key={id}
          className={
            control === node.frontmatter.label || control === "all categories"
              ? "col-wrapper"
              : "none"
          }
        >
          <div className="gatsby-link-tag" id={`act-${id}`} />
          <div className="col col--mod">
            <h2 className="title">{node.frontmatter.title}</h2>
            <h3 className="date">{node.frontmatter.date}</h3>
            <p
              className="ph"
              dangerouslySetInnerHTML={{
                __html: node.html,
              }}
            />
          </div>
          <ActivityImg
            image={getImage(node.frontmatter.image)}
            altName={node.frontmatter.image}
            label={node.frontmatter.label}
          />
        </div>
      ))}
    </section>
  )
}

export default ActivityList

ActivityList.defaultProps = {
  control: "all categories",
}
