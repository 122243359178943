import React from "react"
import "./labels.css"

const Labels = ({ func, active, lb }) => {
  return (
    <section className="section-labels">
      <div className="rad-btns">
        {lb.map((l, i) => (
          <div className="rad-btn" key={i}>
            <p className="label">{l}</p>
            <button
              onClick={() => func(l)}
              className={active === l ? `active-${i} select` : "select"}
              aria-label={"Select-Btn"}
            />
          </div>
        ))}
      </div>
    </section>
  )
}

export default Labels

Labels.defaultProps = {
  active: "all categories",
}
