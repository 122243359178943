import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/page-title"
import Labels from "../components/Labels/labels"
import ActivityList from "../components/ActivityList/activity-list"
import { useStateValue } from "../contexts/state-context-provider"
import { pageContentActivities } from "../markdown-text/content"
import { tranverter, getLanguageActivities } from "../utils/utils"

const ActivitiesPage = () => {
  const { language, data } = useStateValue()
  const { markdown, images } = data

  const lb = pageContentActivities[language].labels
  const lbEn = pageContentActivities.en.labels
  const initialState = lb[0]

  const [active, setActive] = useState(initialState)

  return (
    <Layout>
      <SEO title="Activities" />
      <PageTitle hTitle={pageContentActivities[language].h} />
      <Labels func={setActive} active={active} lb={lb} />
      <ActivityList
        control={tranverter(active, lb, lbEn)}
        activities={getLanguageActivities(markdown, language)}
        images={images.edges}
      />
    </Layout>
  )
}

export default ActivitiesPage
