import React, { useState, useEffect } from "react"
import "./activity-list.css"

const ActivityImg = ({ image, altName, label }) => {
  const [imageView, setImageView] = useState(false)

  useEffect(() => {
    const body = document.querySelector("html")
    if (imageView) {
      body.style.overflow = "hidden"
    } else {
      body.style.overflow = "hidden scroll"
    }
  }, [imageView])
  return (
    <div className={!imageView ? "col col-img artifacts" : "fullview"}>
      <img
        src={image}
        alt={`${altName}`}
        className={`promo-img promo-img-${label}`}
        onClick={() => setImageView(!imageView)}
      />
      <div className={`label label-${label}`}>{label}</div>
    </div>
  )
}

export default ActivityImg
