// find image
const cloudPath = "gatsby-source-cloudinary/activities/"

// get Image for markdown name
const getImage = (markdownItem, images) => {
  const name = markdownItem.frontmatter.image
  const final = images.edges.filter(
    img => img.node.public_id === `${cloudPath}${name}`
  )
  return final[0].node.secure_url
}

// get markdown per langugage
const getLanguageActivities = (markdown, language) => {
  return markdown.nodes.filter(
    activity => activity.frontmatter.lang === language
  )
}
// get two
const getFirstTwoActivities = markdownList => {
  return markdownList.slice(0, 2)
}
// get index in english only
const tranverter = (active, lb, lbEn) => {
  return lbEn[lb.indexOf(active)]
}

export {
  cloudPath,
  getImage,
  getLanguageActivities,
  getFirstTwoActivities,
  tranverter,
}
